<template>
  <v-card tile>
    <v-toolbar color="primary" dark>
      <v-btn dark icon @click.native="$emit('close-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer/>
      <v-toolbar-title :style="{ 'margin-left': '-24px' }">
        {{ $vuetify.lang.t('$vuetify.pages.more.branchPage.title') }}
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <v-card class="mb-6" tile>
      <v-img :alt="`${site.name} logo`" :aspect-ratio="16/9" :src="site.logo" contain/>
    </v-card>
    <v-card-text>
      <kurcc-site-branch-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.generalInfo')"
        :items="generalInfoSectionData" class="mb-5"/>
      <kurcc-site-branch-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.branchContactInfo')"
        :items="branchContactInfoSectionData" class="mb-5"/>
      <kurcc-site-branch-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.employeeInfo')"
        :items="employeeInfoSectionData" class="mb-5"/>
      <kurcc-site-branch-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.inspectionStatus')"
        :items="inspectionStatusSectionData" class="mb-5"/>
    </v-card-text>
  </v-card>
</template>

<script>
import Branch from '@/modules/app/models/branch'

export default {
  name: 'KurccSiteBranchDialogContent',
  props: {
    branch: {
      type: Branch,
      required: false
    }
  },
  components: {
    KurccSiteBranchDialogContentSection: () => import('@/modules/more/components/KurccSiteBranchDialogContentSection')
  },
  computed: {
    site () {
      return this.branch.site
    },
    inspection () {
      return this.branch.inspection
    },
    haveInspection () {
      return !this.$lodash.isNil(this.inspection)
    },
    generalInfoSectionData () {
      return [
        {
          icon: 'mdi-store',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.siteName'),
          subtitle: this.site.name
        },
        {
          icon: 'mdi-calendar-check',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.lastInspection'),
          subtitle: this.branch.last_done_inspection?.inspection_ended_at
        },
        {
          icon: 'mdi-calendar-arrow-right',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.nextInspection'),
          subtitle: this.branch.last_done_inspection ? this.branch.last_done_inspection.inspection_due_date : this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.noDueInspection')
        },
        {
          icon: 'mdi-bookmark-multiple-outline',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.category'),
          subtitle: this.site.category.name
        },
        {
          icon: 'mdi-trending-up',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.grade'),
          subtitle: 'TODO'
        }
      ]
    },
    branchContactInfoSectionData () {
      return [
        {
          icon: 'mdi-map-marker-outline',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.address'),
          subtitle: this.branch.full_address
        },
        {
          icon: 'mdi-email-outline',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.email'),
          subtitle: this.site.email
        },
        {
          icon: 'mdi-store-outline',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.branchName'),
          subtitle: `${this.branch.brand_name} #${this.branch.number}`
        }
      ]
    },
    employeeInfoSectionData () {
      return [
        {
          icon: 'mdi-account-supervisor-outline',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.managerName'),
          subtitle: this.branch.manager_name
        },
        {
          icon: 'mdi-cellphone',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.managerPhone'),
          subtitle: this.branch.manager_phone_number
        }
      ]
    },
    inspectionStatusSectionData () {
      return [
        {
          icon: 'mdi-calendar-arrow-right',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.inspectionDueDate'),
          subtitle: this.haveInspection ? `The inspection will due ${this.inspection.inspection_due_date}`
            : this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.noDueInspection')
        },
        {
          icon: 'mdi-account-check',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.inspector'),
          subtitle: this.haveInspection ? this.inspection.inspector
            ? `${this.inspection.inspector.first_name} ${this.inspection.inspector.last_name}`
            : this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.notAssigned')
            : this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.noDueInspection')
        },
        {
          icon: 'mdi-clock-alert-outline',
          title: this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.deadlineDays'),
          subtitle: this.haveInspection ? this.inspection.deadline_days : this.$vuetify.lang.t('$vuetify.pages.more.branchPage.sections.titles.noDueInspection')
        }
      ]
    }
  }
}
</script>
